import axios from "axios";
import { AppUtils } from "../utils/appUtils";
import { IS_SESSION_EXPIRED } from "../utils/constants";
import { SessionStorage } from "../utils/storage/sessionStorage";
import { AppMisc } from "../misc/appMisc";

export const LOCAL_CONSTANTS = {
  BASE_URL: "http://localhost:8090/api/"
};

export const DEV_CONSTANTS = {
  BASE_URL: "https://pis.asktrek.com/api/"
};

export const PROD_CONSTANTS = {
  BASE_URL: "https://pis.samyantra.org/api/"
};

const BASE_URL = PROD_CONSTANTS.BASE_URL;

export const API_URL = {
  login: BASE_URL + "login",
  user: BASE_URL + "user",
  office: BASE_URL + "office",
  logout: BASE_URL + "logout",
  setting: BASE_URL + "setting",
  resetPassword: BASE_URL + "public/reset-password",
  forgetPassword: BASE_URL + "public/forget-password",
  contactUs: BASE_URL + "public/contact-us",
  changePassword: BASE_URL + "user/change-password",
  officeUsers: BASE_URL + "user/office-users",
  profile: BASE_URL + "user/update-profile",
  staff: BASE_URL + "user/staff-detail",
  leaveDetails: BASE_URL + "user/leave-details",
  staffBasicInfo: BASE_URL + "user/staff-detail/basic-info",
  staffLeave: BASE_URL + "user/leave-info",
  leaveReport: BASE_URL + "user/leave-report",
  palikaStaffs: BASE_URL + "user/staffs",
  healthExpenses: BASE_URL + "user/health-expenses",
  departmentalPunishment: BASE_URL + "user/departmental-punishment",
  publicRepresentative: BASE_URL + "user/public-representative",
  businessException: BASE_URL + "business-exception",
  importFromExcel: BASE_URL + "excel-file",
  department: BASE_URL + "department",
  subDepartment: BASE_URL + "subdivision",
  category: BASE_URL + "category",
  public: BASE_URL + "public",
  validateCard: BASE_URL + "public/validate",
  validatePublicRepresentativeCard: BASE_URL + "public/validate-public-representative-card",
  academicDegree: BASE_URL + "academic-degree",
  uploadStaffExcel: BASE_URL + "user/staff-detail/upload-staff-excel",
  post: BASE_URL + "post",
  downloadExcelSample: BASE_URL + "excel-file/sample-file",
  databaseOperations: BASE_URL + "user/database-operations",
};

const PIS = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true
});

PIS.interceptors.request.use(
  config => {
    if (!AppMisc.hideFullBodyLoader(config.url, config.method)) {
      document.body["children"].root.classList.add('body-loader');
    }
    return config;
  },
  error => Promise.reject(error)
);

PIS.interceptors.response.use(
  function (response) {
    document.body["children"].root.classList.remove('body-loader');
    return response;
  },
  function (error) {
    document.body["children"].root.classList.remove('body-loader');
    if (error.response.status === 401 || error.response.status === 403) {
      AppUtils.removeUserRef();
      SessionStorage.setItem(IS_SESSION_EXPIRED, "true");
      window.location.href = "/";
    } else {
      return Promise.reject(error);
    }
  }
);

export default PIS;